import { illust } from "_styles/shared/icons";

export const getExtensionIcon = (extension) => {
  switch (extension) {
    case "folder":
      return illust.folder[36];

    case "file":
      return illust.doc;

    case "zip":
      return illust.folder.zip;

    case "png":
      return illust.jpg;

    case "jpg":
      return illust.jpg;

    case "pdf":
      return illust.pdf;

    default:
      break;
  }
};

export const handleCopy = (text: string) => {
  navigator.clipboard.writeText(text).then(() => {});
};
