import React, {
  cloneElement,
  forwardRef,
  isValidElement,
  memo,
  useEffect,
  useId,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import Image from "next/image";
import styled, { useTheme } from "styled-components";

import DragIcon from "./drag.png"; // TODO: S3 Icon 작업 끝나면 수정 필요
import { TextareaProps } from "./types";
import useVerticalResize from "./useVerticalResize";

/**
 * @example
 * <Textarea
    label="내용" or label={<Typography>내용</Typography>}
    error={error1}
    onChange={handle1Change}
    value={value1}
    errorMessage="내용을 입력해주세요."
    placeholder="내용을 입력해주세요."
    ref={ref}
    maxLength={200}
    // style={{ height: "200px" }}
    // textCount={false}
    // resize={false}
  />
 */
const Textarea = forwardRef(function Textarea(
  props: TextareaProps,
  ref: React.ForwardedRef<HTMLTextAreaElement>,
) {
  const {
    label,
    required,
    id: propId,
    error: forwardError,
    onChange,
    errorMessage: forwardErrorMessage,
    value = "",
    maxLength,
    textCount = true,
    resize = true,
    ...rest
  } = props;

  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [isFocus, setIsFocus] = useState(false);
  const [errorMessage, setErrorMessage] = useState(forwardErrorMessage || "");
  const [error, setError] = useState(forwardError || false);
  const [textareaSize, setTextareaSize] = useState<{ height: string | number }>(
    { height: "auto" },
  );
  const generatedId = useId();
  const id = propId || generatedId;

  const theme: any = useTheme();

  const { startResize } = useVerticalResize(textareaRef, setTextareaSize);

  useEffect(() => {
    if (props.style?.height) {
      setTextareaSize({ height: props.style?.height });
    }
  }, [props.style?.height]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (maxLength && maxLength < e.target.value.length) {
      setErrorMessage("최대 글자수를 초과하였습니다.");
      setError(true);
      return;
    } else {
      setErrorMessage("");
      setError(false);
    }

    if (onChange) {
      onChange(e);
    }
  };

  const renderLabel = () => {
    const labelStyle = {
      transition: "color 0.3s",
      cursor: "pointer",
      color: error
        ? theme.palette.system_error
        : isFocus
          ? theme.palette.primary_main
          : theme.palette.text_10,
    };

    if (typeof label === "string") {
      return (
        <label htmlFor={id} style={labelStyle}>
          {label}
          {required && (
            <span
              style={{ color: theme.palette.system_error, marginLeft: "4px" }}
            >
              *
            </span>
          )}
        </label>
      );
    }

    if (isValidElement(label)) {
      const labelContent = cloneElement<any>(label, {
        style: {
          ...(label as React.ReactElement<any>).props.style,
          ...labelStyle,
        },
      });

      return (
        <label htmlFor={id} style={{ cursor: "pointer" }}>
          {labelContent}
        </label>
      );
    }

    return label;
  };

  useImperativeHandle(ref, () => ({
    focus: () => {
      textareaRef.current?.focus();
    },
    blur: () => {
      textareaRef.current?.blur();
    },
    ...textareaRef.current,
    textareaSize,
    isFocus,
    generatedId,
  }));

  return (
    <S.InputContainer>
      <S.TopWrapper>{renderLabel()}</S.TopWrapper>

      <S.TextareaWrapper isFocus={isFocus} error={error}>
        <S.TextareaBase
          {...rest} //
          id={id}
          ref={textareaRef}
          onChange={handleChange}
          onFocus={() => setIsFocus(true)}
          onBlur={() => setIsFocus(false)}
          value={value}
          style={{ height: textareaSize.height }}
        />
        {resize && (
          <S.ResizeHandle
            src={DragIcon}
            alt="drag-icon"
            onMouseDown={startResize} //
            onMouseOver={() => setIsFocus(true)}
            onMouseOut={() => setIsFocus(false)}
          />
        )}
      </S.TextareaWrapper>

      <S.BottomWrapper>
        {error || errorMessage ? (
          <S.ErrorMessage>{errorMessage}</S.ErrorMessage>
        ) : (
          <div />
        )}
        {textCount && maxLength && (
          <S.TextCount>{`${value?.length} / ${maxLength}`}</S.TextCount>
        )}
      </S.BottomWrapper>
    </S.InputContainer>
  );
});

const S = {
  TextCount: styled("span")<any>((props: any) => ({
    ...props.theme.typography["caption.100"],
    color: props.theme.palette.text_10,
  })),
  InputContainer: styled("div")({
    display: "flex",
    flexDirection: "column",
  }),
  TopWrapper: styled("div")({
    display: "flex",
    flexDirection: "row",
    marginLeft: "4px",
  }),
  BottomWrapper: styled("div")({
    display: "flex",
    justifyContent: "space-between",
  }),
  Label: styled("label")<any>((props: any) => ({
    ...props.theme.typography["caption.100"],
    // margin: "0px 0px 8px 4px",
    margin: "0px 0px 8px 0px",
    color: props.error
      ? "red"
      : props.isFocus
        ? props.theme.palette.primary_main
        : null,
    transition: "color 0.3s",
    cursor: "pointer",
  })),
  TextareaWrapper: styled("div")<any>((props: any) => ({
    border: `1px solid ${props.error ? "red" : props.isFocus ? props?.theme?.palette?.primary_main : "transparent"}`,
    position: "relative",
    display: "flex",
    transition: "border 0.3s",
    background: props?.theme?.palette?.background?.default,
    borderRadius: "8px",
    "& textarea": {
      marginRight: "8px",
    },
    "& textarea::-webkit-scrollbar": {
      width: "2px",
    },
    "& textarea::-webkit-scrollbar-thumb": {
      backgroundColor: props.theme.palette.line_20,
      borderRadius: "9px",
    },
    "& textarea::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
  })),
  TextareaBase: styled("textarea")<any>((props) => ({
    borderRadius: "8px",
    border: "none",
    outline: "none",
    background: props.theme.palette.background.default,
    ...props.theme.typography["body.200"],
    padding: "13px 16px 13px 16px",
    width: "100%",
    resize: "none",
    color: props.theme.palette.text_10,
    "::placeholder": {
      color: props.theme.palette.text_30,
      ...props.theme.typography["body.200"],
    },
  })),
  ResizeHandle: styled(Image)({
    position: "absolute",
    bottom: "0px",
    right: "0px",
    cursor: "ns-resize",
    borderRadius: "3px",
    opacity: 0,
    transition: "opacity 0.3s",
    "&:hover": {
      opacity: 1,
    },
  }),
  Required: styled("span")((props) => ({
    ...props.theme.typography["caption.100"],
    margin: "0px 0px 8px 4px",
    color: props.theme.primary_main,
  })),
  ErrorMessage: styled("span")<any>((props: any) => ({
    ...props.theme.typography["caption.100"],
    margin: "4px 0px 0px 4px",
    color: props.theme.system_error,
  })),
};
const MemoTextarea = memo(Textarea);

export default MemoTextarea;
