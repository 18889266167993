import React, {
  cloneElement,
  forwardRef,
  isValidElement,
  memo,
  useId,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import Typography from "_components/Typography";
import { icons } from "_styles/shared/icons";
import Image from "next/image";
import styled, { useTheme } from "styled-components";

import { InputBaseProps } from "./types";

const INPUT_HEIGHT_LIST = {
  "40": "40px",
  "44": "44px",
  "32": "32px",
};
// TODO : 텍스트 입력시 clear 버튼이 자리를 차지해서 넓어짐
// TODO : 인풋 에러메시지 위치 처리 필요

const getBorderStyle = (props) => {
  switch (true) {
    case props.error:
      return `1px solid ${props.theme.palette.system_error}`;
    case props.isFocus:
      return `1px solid ${props.theme.palette.primary_main}`;
    default:
      return `1px solid ${props.inputWrapperStyle?.borderColor || props.theme.palette.background.default}`;
  }
};

const Input = forwardRef(function Input(
  props: InputBaseProps,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  const {
    size = "40",
    label,
    subLabel,
    value = "",
    onChange,
    id: propId,
    required,
    error,
    disabled,
    errorMessage,
    endAdornment,
    fullWidth = false,
    clearIcon,
    style,
    placeholderStyle,
    inputStyle,
    inputWrapperStyle,
    onFocus,
    onBlur,
    ...rest
  } = props;

  const [isFocus, setIsFocus] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const generatedId = useId();
  const id = propId || generatedId;
  const theme: any = useTheme();

  const handleClear = () => {
    if (onChange) {
      onChange({
        target: { value: "" },
      } as React.ChangeEvent<HTMLInputElement>);
      inputRef.current?.focus();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e);
    }
  };

  const renderLabel = () => {
    if (typeof label === "string") {
      return (
        <label htmlFor={id} style={{ cursor: "pointer" }}>
          {label}
          {required && (
            <span
              style={{ color: theme.palette.system_error, marginLeft: "4px" }}
            >
              *12431234
            </span>
          )}
        </label>
      );
    }

    if (isValidElement(label)) {
      const labelContent = cloneElement<any>(label, {
        style: {
          ...label.props.style,
        },
      });

      return (
        <label htmlFor={id} style={{ cursor: "pointer" }}>
          {labelContent}
        </label>
      );
    }

    return label;
  };

  useImperativeHandle(ref, () => ({
    ...inputRef.current,
  }));

  const handleOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocus(true);
    onFocus && onFocus(e);
  };
  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocus(false);
    onBlur && onBlur(e);
  };

  return (
    <S.InputContainer fullWidth={fullWidth} style={style}>
      {(label || subLabel) && (
        <S.TopWrapper>
          {renderLabel()}

          {typeof subLabel === "string" ? (
            <Typography variant="body.200" color="text_10">
              {subLabel}
            </Typography>
          ) : (
            subLabel
          )}
        </S.TopWrapper>
      )}

      <S.InputWrapper
        isFocus={isFocus}
        error={error}
        disabled={disabled}
        size={size}
        inputWrapperStyle={inputWrapperStyle}
      >
        <S.InputBase
          {...rest}
          ref={inputRef}
          id={id}
          value={value}
          onChange={handleChange}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          disabled={disabled}
          placeholderStyle={placeholderStyle}
          autoComplete="off"
          inputStyle={inputStyle}
        />
        <S.EndAdornmentWrapper>
          {value && !disabled && (
            <S.ClearButton onClick={handleClear}>
              <Image
                onClick={handleClear}
                src={clearIcon ?? icons.close.bgray400_12}
                alt="clear-icon"
                width={clearIcon ? 14 : 12}
                height={clearIcon ? 14 : 12}
              />
            </S.ClearButton>
          )}
          {endAdornment}
        </S.EndAdornmentWrapper>
      </S.InputWrapper>

      {errorMessage && (
        <S.ErrorMessage errorMessage={errorMessage} error={error}>
          {errorMessage}
        </S.ErrorMessage>
      )}
    </S.InputContainer>
  );
});

const S = {
  InputContainer: styled("div")<any>((props: any) => ({
    display: "flex",
    flexDirection: "column",
    width: props.fullWidth && "100%",
    ...props.style,
  })),
  TopWrapper: styled("div")({
    display: "flex",
    flexDirection: "column",
    // margin: "0 0 8px 4px",
    margin: "0 0 8px 0px",
  }),
  ErrorMessage: styled("span")<any>((props: any) => ({
    margin: "4px 0 0 4px",
    opacity: props.error && props.errorMessage ? 1 : 0,
    height: "16px",
    color: props.theme.palette.system_error,
    ...props.theme.typography["caption.100"],
  })),
  Label: styled("label")<{ isFocus: boolean; error: boolean }>(
    (props: any) => ({
      color: props.error
        ? props.theme.palette.system_error
        : props.isFocus
          ? props.theme.palette.primary_main
          : props.theme.palette.text_10,
      transition: "color 0.3s",
      cursor: "pointer",
      ...props.theme.typography["caption.100"],
    }),
  ),
  Required: styled("span")((props: any) => ({
    margin: "0 0 8px 4px",
    color: props.theme.palette.system_error,
    ...props.theme.typography["caption.100"],
  })),
  InputWrapper: styled("div")<any>((props) => ({
    display: "flex",
    alignItems: "center",
    background: props.backgroundColor || props.theme.palette.background.default,
    borderRadius: "8px",
    height: INPUT_HEIGHT_LIST[props.size],
    border: getBorderStyle(props),
    transition: "border 0.3s",
    opacity: props.disabled ? 0.5 : 1,
    ...props.inputWrapperStyle,
  })),
  InputBase: styled("input")<any>((props: any) => ({
    flex: 1,
    background: "transparent",
    border: "1px solid transparent",
    padding: "11px 16px",
    color: props.theme.palette.text_10,
    ...props.theme.typography["body.200"],
    "&:focus": {
      outline: "none",
    },
    "&::placeholder": {
      ...(props.placeholderStyle || {
        color: props.theme.palette.text_30,
        ...props.theme.typography["body.200"],
      }),
    },
    ...props.inputStyle,
  })),
  EndAdornmentWrapper: styled("div")({
    display: "flex",
    alignItems: "center",
    columnGap: "13px",
    paddingRight: "16px",
  }),
  ClearButton: styled("div")(() => ({
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "24px",
    height: "24px",
  })),
};

const MemoInput = memo(Input);

export default MemoInput;
