import { useEffect } from "react";

const useVerticalResize = (
  textareaRef: React.RefObject<HTMLTextAreaElement>,
  setTextareaSize: (size: { height: string }) => void,
) => {
  const startResize = (e: React.MouseEvent) => {
    e.preventDefault();
    window.addEventListener("mousemove", handleResize);
    window.addEventListener("mouseup", stopResize);
  };

  const handleResize = (e: MouseEvent) => {
    if (textareaRef.current) {
      textareaRef.current?.focus();
      const newHeight =
        e.clientY - textareaRef.current.getBoundingClientRect().top;
      setTextareaSize({
        height: `${newHeight}px`,
      });
    }
  };

  const stopResize = () => {
    textareaRef.current?.blur();
    window.removeEventListener("mousemove", handleResize);
    window.removeEventListener("mouseup", stopResize);
  };

  useEffect(() => {
    return () => {
      window.removeEventListener("mousemove", handleResize);
      window.removeEventListener("mouseup", stopResize);
    };
  }, []);

  return {
    startResize,
  };
};

export default useVerticalResize;
